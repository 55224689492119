import {
    MainBlock,
    Conditions,
    CalculatorBlock,
    RequestBlock,
    MediaBlock,
    ProductsBlock,
    EBlocks,
    CallbackModal
} from '@/components'
import { useEffect, useRef, useState } from 'react'
import { useCalculator } from '@/contexts/CalculatorContext'
import createConditions from '@/lib/createConditions'
import getWindowWidth from '@/lib/getWindowWidth'
import api from '@/lib/api'

export default function ({ product }) {
    const { form, setCalculator, rate, termType, calcForm } = useCalculator()
    const calcRef = useRef()
    const requestRef = useRef()
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const windowWidth = getWindowWidth()

    useEffect(() => {
        product?.calculator && setCalculator(product.calculator)
    }, [product])

    return (
        <>
            <MainBlock
                title={product.title}
                text={product.description}
                img={product.preview}
                breadcrumbs={
                    (product.type === 'type_leasing_operational_page' ||
                        product.type === 'type_leasing_target_page' ||
                        product.type === 'type_credit_target_page') &&
                    product.category?.parent?.has_page && [
                        {
                            title: product.category.parent.name,
                            link: '/[slug]',
                            as: `/${product.category.parent.slug}`,
                        },
                        { title: product.title },
                    ]
                }
                buttonText={
                    product.type === 'type_leasing_general_page' ||
                    product.type === 'type_leasing_target_page' ||
                    product.type === 'type_leasing_operational_page'
                        ? 'Рассчитать лизинг'
                        : product.type === 'type_refinancing_page'
                        ? 'Рассчитать рефинансирование'
                        : 'Рассчитать кредит'
                }
                largeButton={product.type === 'type_refinancing_page'}
                onBtnClick={
                    requestRef.current &&
                    (() => calcRef.current.scrollIntoView({ behavior: 'smooth' }))
                }
            />
            {createConditions(product)?.length > 0 && (
                <>
                    <Conditions
                        conditions={createConditions(product)}
                        onBtnClick={
                            (product.type === 'type_credit_target_page' ||
                                product.type === 'type_refinancing_page') &&
                            requestRef.current &&
                            (() => {
                                if(windowWidth > 768) {
                                    calcRef.current.scrollIntoView({ behavior: 'smooth' });
                                } else {
                                  setModalIsOpen(true);  
                                }
                            })
                        }
                        center={product.type === 'type_refinancing_page'}
                        grey={
                            product.type === 'type_refinancing_page' ||
                            product.type === 'type_credit_target_page'
                        }
                    />
                    {(windowWidth <= 768) && 
                        <CallbackModal
                            isOpen={modalIsOpen}
                            onClose={setModalIsOpen}
                            title="Оставьте заявку на получение денег"
                            text="Укажите свои данные, чтобы мы смогли с вам связаться."
                            onSubmitMethod={(values) => {
                                api.customRecord({
                                    ...values,
                                    credit_type: `Онлайн-заявка "${product.title}"`,
                                })
                            }}
                        />
                    }
                </>
            )}
            {product.calculator && (
                <CalculatorBlock
                    calculator={product.calculator}
                    blockRef={calcRef}
                    onBtnClick={() => requestRef.current.scrollIntoView({ behavior: 'smooth' })}
                />
            )}
            <EBlocks blocks={product.blocks} title={product.title} />
            {product.calculator &&
                form.values.sum >= product.calculator.points[0].amount_from &&
                form.values.sum <=
                    product.calculator.points[product.calculator.points.length - 1].amount_to && (
                    <RequestBlock
                        title={product.title}
                        form={form}
                        values={{ ...form.values, ...calcForm.values, rate: rate }}
                        calculator={product.calculator}
                        termType={termType}
                        blockRef={requestRef}
                    />
                )}
            <MediaBlock />
            {product.relation_pages?.length > 0 && (
                <ProductsBlock
                    pt="small"
                    title={product.relation_pages_title}
                    products={product.relation_pages}
                />
            )}
        </>
    )
}
