import styled, { css } from 'styled-components'
import { rgba, maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'
import {StyledContainer} from '@/components/styles'

const Main = styled.div`
    position: relative;
    padding: 64px 0 ${p => p.pb === 'small' ? "72px" : "110px"};
    ${maxWidth(vars.breakpoints.tablet)`
        padding: 48px 0 ${p => p.pb === 'small' ? "72px" : "90px"};
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: ${p => p.main ? "320px" : p.ptLarge ? "375px" : "275px"} 0 32px;
    `}
`

Main.Section = styled.section`
    position: relative;
    background-color: ${p => p.dark ? "#68869F" : p.green ? "#274A52" : vars.theme.lightGrey};
    background-image: ${p => p.backImage && `url(${p.backImage}), `}
         ${p => p.dottedBackground && (p.green ? "linear-gradient(50deg, #051C31 2.69%, #274A52 50.31%, rgba(255,255,255,0) 80%), " : `linear-gradient(70deg, ${p.dark ? "#89A6BE" : vars.theme.lightGrey} 45%, rgba(255,255,255,0) 80%), `)}
         ${p => p.dottedBackground && `radial-gradient(${(p.dark || p.green) ? rgba(vars.theme.outlineGrey, .6) : "#fff"} 10%, rgba(255,255,255,0) 20%)`};
    background-size: ${p => p.backImage && "contain, "} ${p => p.dottedBackground && `cover, 25px 25px`};
    background-position: ${p => p.backImage && "top right, "} ${p => p.dottedBackground && `center, top left`};
    background-repeat: ${p => p.backImage && "no-repeat, "} ${p => p.dottedBackground && `no-repeat, repeat`};
    ${StyledContainer} {
        position: relative;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        background-image: 
             ${p => p.dottedBackground && (p.green ? "linear-gradient(50deg, #051C31 2.69%, #274A52 50.31%, rgba(255,255,255,0) 80%), " : `linear-gradient(70deg, ${p.dark ? "#89A6BE" : vars.theme.lightGrey} 45%, rgba(255,255,255,0) 80%), `)}
             ${p => p.backImage && `url(${p.backImage}), `}
             ${p => p.dottedBackground && `radial-gradient(${(p.dark || p.green) ? rgba(vars.theme.outlineGrey, .6) : "#fff"} 10%, rgba(255,255,255,0) 20%)`};
        background-size: ${p => p.dottedBackground && `cover, `} ${p => p.backImage && "contain, "} ${p => p.dottedBackground && `20px 20px`};
        background-position: ${p => p.dottedBackground && `center,`} ${p => p.backImage && "top right, "} ${p => p.dottedBackground && `top left`};
        background-repeat: ${p => p.dottedBackground && `no-repeat,`} ${p => p.backImage && "no-repeat, "} ${p => p.dottedBackground && `repeat`};
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.dottedBackground && css`
            background-image: 
                 ${p.backImage && p.green && "linear-gradient(180deg, rgba(255,255,255,0) 170px, #051C31 260px), "}
                 ${p.backImage && `url(${p.backImage}), `}
                 ${p.dottedBackground && (p.green ? "linear-gradient(30deg, #051C31 20%, #274A52 58%, rgba(255,255,255,0) 80%), " : `linear-gradient(20deg, ${p.dark ? "#89A6BE" : vars.theme.lightGrey} 65%, rgba(255,255,255,0) 80%), `)}
                 ${p.dottedBackground && `radial-gradient(${(p.dark || p.green) ? rgba(vars.theme.outlineGrey, .6) : "#fff"} 10%, rgba(255,255,255,0) 20%)`};
        `}
        background-size: ${p => p.backImage && "cover, auto 260px, "} ${p => p.dottedBackground && `cover, 15px 15px`};
        background-position: ${p => p.backImage && "center, top right, "} ${p => p.dottedBackground && `center, top left`};
        background-repeat: ${p => p.backImage && "no-repeat, no-repeat, "} ${p => p.dottedBackground && `no-repeat, repeat`};
    `}
`

Main.Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: ${p => p.reverse ? "column-reverse" : "column"};
    justify-content: space-between;
    width: ${p => p.large ? "600px" : "470px"};
    height: ${p => p.smallHeight ? "276px" : "356px"};
    z-index: 1;
    ${maxWidth(vars.breakpoints.sDesktop)`
        height: 310px;
    `}
    ${maxWidth(vars.breakpoints.tablet)`
        height: ${p => p.main ? "270px" : "220px"};
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        height: auto;
        width: 100%;
    `}
`

Main.Top = styled.div`
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => !p.reverse && "margin-bottom: 24px;"}
    `}
`

Main.Bottom = styled.div`
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.reverse && "display: none;"}
    `}
`

Main.Text = styled.div`
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: ${p => p.white ? "#FFFFFF" : vars.theme.darkGrey};
    ${maxWidth(vars.breakpoints.mTablet)`
        font-size: 16px;
        line-height: 24px;
    `}
`

Main.Buttons = styled.div`
    display: grid;
    grid-template-columns: 210px 150px;
    gap: 16px;
    ${maxWidth(vars.breakpoints.mTablet)`
        grid-template-columns: 1fr 1fr;
    `}
`

Main.Conditions = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    color: #fff;
    ${maxWidth(vars.breakpoints.tablet)`
        margin-bottom: 4px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 0;
    `}
`

Main.Condition = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px;
    padding-left: 16px;
    border-left: 1px solid #fff;
    ${p => p.full && css`
        width: 70%;
        margin-bottom: 24px;
    `}
    ${p => p.large && css`
        width: 43%;
    `}
    ${p => p.medium && css`
        width: 33%;
    `}
    ${p => p.small && css`
        width: 23%;
    `}
    
    ${maxWidth(vars.breakpoints.mTablet)`
        width: 100%;
        &:first-child {
            width: 100%;
        }
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        font-size: 14px;
        line-height: 20px;
    `}
`

Main.Img = styled.div`
    position: absolute;
    height: 100%;
    right: 0;
    bottom: 0;
    img {
        height: 100%;
        max-height: 530px;
        object-fit: contain;
        min-width: 400px;
    }
    z-index: 1;
    ${maxWidth(850)`
        ${p => p.main && "display: none;"}
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        display: flex;
        justify-content: center;
        height: ${p => p.dark ? "300px" : p.largeImg ? "375px" : "260px"};
        bottom: auto;
        top: 0;
        left: -20px;
        right: -20px;
        overflow: hidden;
        picture {
            display: flex;
            justify-content: center;
        }
        img {
            max-width: initial;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100px;
            background-image: linear-gradient(rgba(255,255,255,0) 0%, ${p => p.dark ? "#89A6BE 75%" : `${vars.theme.lightGrey} 95%`});
        }
    `}
`

Main.SliderArrows = styled.div`
    position: absolute;
    right: 40px;
    bottom: 110px;
`

export default Main
