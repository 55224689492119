import { MainBlock, Advantages, EBlocks } from '@/components'
import styled from 'styled-components'

const advantages = [
    {
        icon: { title: 'settings' },
        title: 'Кредитование «под ключ»',
        description:
            'В своей работе мы не ограничиваемся лишь подбором кредитного продукта. Оказываем информационную поддержку, исправляем кредитную историю, даем рекомендации по финансовому благополучию бизнеса',
    },
    {
        icon: { title: 'analysis' },
        title: 'Технология 3Q-андеррайтинга',
        description:
            'Применение уникального формата анализа заявки клиента позволяет выявлять и исправлять слабые стороны, добиваться эксклюзивно низких ставок по кредиту в индивидуальном порядке',
    },
    {
        icon: { title: 'money' },
        title: 'Партнерская сеть',
        description:
            'Партнерские отношения с крупнейшими кредитными организациями обеспечивают ежедневное поступление актуальной информации о новых продуктах, условиях, требованиях. Мы в курсе всех новостей',
    },
    {
        icon: { title: 'purchases' },
        title: 'Прозрачная система лояльности',
        description:
            'Мы ценим доверие наших клиентов! В знак благодарности предлагаем более упрощенные условия при повторном обращении. Рекомендуя наши услуги друзьям, вы получаете приятные бонусы',
    },
]

export default function ({ data }) {
    return (
        <>
            <MainBlock
                title={data.title}
                text={data.description}
                backImage={data.preview.preview_530}
                facts={[data.fact_1, data.fact_2, data.fact_3]}
                largeContent
                whiteContent
                aboutCompany
            />
            <PageInner>
                <EBlocks
                    blocks={data.blocks}
                    createAdvantages={() => (
                        <Advantages
                            pb="small"
                            pt="small"
                            title="Наши премущества"
                            subtitle="Мы помогаем получить финансовую поддержку на ваших условиях, а не продаем готовые банковские услуги"
                            blocks={advantages}
                            whiteContent
                        />
                    )}
                />
            </PageInner>
        </>
    )
}

const PageInner = styled.div`
    background: linear-gradient(163.47deg, #284b53 14.99%, #07233d 87.83%);
`
