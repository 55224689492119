import getConfig from 'next/config'
import axios from 'axios'
import qs from 'qs'
import cookies from '@/lib/cookies'

const { publicRuntimeConfig } = getConfig()

const isClient = () => typeof window !== 'undefined'

const API_URL = isClient() ? publicRuntimeConfig.browserApiUrl : publicRuntimeConfig.apiUrl

const fullUrl = (url, slug) => {
    return slug ? `${API_URL}/${url}/${slug}` : `${API_URL}/${url}`
}

const generateKey = (base, params = {}) => {
    let key = `/${base}`
    const paramsKeys = Object.keys(params)
    paramsKeys.length > 0 &&
        paramsKeys.forEach((item, ind) => {
            if (ind === 0) {
                key = `${key}?${item}=${params[item]}`
            } else {
                key = `${key}&${item}=${params[item]}`
            }
        })

    return key
}

export function createApi(tokenExtractor) {
    const getHeaders = () => {
        const token = tokenExtractor()
        const headers = {}

        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        return headers
    }

    let cacheData = {}

    function fillCache(data) {
        cacheData = { ...data }
    }

    function extractCache() {
        return { ...cacheData }
    }

    function toFormData(obj, form, namespace) {
        let fd = form || new FormData()
        let formKey

        for (let property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (namespace) {
                    formKey = namespace + '[' + property + ']'
                } else {
                    formKey = property
                }

                if (obj[property] instanceof Date) {
                    fd.append(formKey, obj[property].toISOString())
                } else if (
                    typeof obj[property] === 'object' &&
                    obj[property] !== null &&
                    !(obj[property] instanceof File)
                ) {
                    toFormData(obj[property], fd, formKey)
                } else {
                    fd.append(formKey, obj[property] === null ? '' : obj[property])
                }
            }
        }

        return fd
    }

    const get = (url, shouldCache = true) => (query = {}) => {
        const { cancelToken = axios.CancelToken.source().token, ...dirtyParams } = query

        const params = {}

        Object.keys(dirtyParams).forEach((key) => {
            let value = dirtyParams[key]
            if (key.includes('[]')) {
                key = key.replace('[]', '')
                value = Array.isArray(value) ? value : [value]
            }
            params[key] = value
        })

        const key = generateKey(url, params)

        if (isClient() && cacheData[key]) {
            // console.warn('return from cache: ', key)
            return cacheData[key]
        }

        return axios
            .get(fullUrl(url), {
                cancelToken,
                params,
                paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'indices' }),
                headers: getHeaders(),
            })
            .then((response) => {
                if (shouldCache) {
                    // console.warn('write in cache: ', key)
                    cacheData[key] = response.data
                }
                return response.data
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    const error = new Error(`Not Found: ${err.response.config.url}`)
                    error.statusCode = 404
                    error.url = err.response.config.url
                    error.params = err.response.config.params
                    error.method = 'get'
                    throw error
                } else {
                    const error = new Error(`Internal Error: ${err.response.config.url}`)
                    error.statusCode = 404
                    error.url = err.response.config.url
                    error.params = err.response.config.params
                    error.method = 'get'
                    throw error
                }
            })
    }

    const post = (url, type = 'json') => (data) => {
        return axios
            .post(fullUrl(url), type == 'form' ? toFormData(data) : data, { headers: getHeaders() })
            .then((response) => response.data)
            .catch((err) => {
                if (err.response.status === 404) {
                    const error = new Error(`Not Found: ${err.response.config.url}`)
                    error.statusCode = 404
                    error.url = err.response.config.url
                    error.params = err.response.config.data
                    error.method = 'post'
                    throw error
                } else {
                    const error = new Error(`Internal Error: ${err.response.config.url}`)
                    error.statusCode = 404
                    error.url = err.response.config.url
                    error.params = err.response.config.params
                    error.method = 'post'
                    if (err.response.data.errors) {
                        error.errors = err.response.data.errors
                    } else if (err.response.data.data) {
                        error.errors = err.response.data.data
                    }
                    if (err.response.data.status) {
                        error.status = err.response.data.status
                    }
                    throw error
                }
            })
    }

    return {
        auth: {
            register: post('auth/registration'),
            login: post('auth/login'),
            sms: post('auth/sms'),
        },
        me: get('me'),
        updateProfile: post('me/update'),
        faq: get('me/faq'),

        dialogs: {
            history: get('dialogs/history', false),
            current: get('dialogs/current', false),
            view: get('dialogs/view', false),
            create: post('dialogs/create', 'form'),
            send: post('dialogs/send', 'form'),
            resolve: post('dialogs/resolved'),
            questions: post('dialogs/questions'),
        },

        categories: get('categories'),
        page: get('pages/show'),
        pageWithSlug: (slug) => get(`pages/${slug}`),

        cities: get('department-cities'),
        office: (id) => get(`departments/${id}`),
        officeReview: post('departments/review'),
        directorRequest: post('director-requests'),
        employeeReview: post('department-employees/review'),
        mobileOffices: get('mobile-office-schedules', false),
        mobileOfficesCities: get('mobile-office-schedules/cities'),
        mobileOfficeRequest: post('mobile-office-requests'),

        articles: {
            list: get('articles'),
            main: get('articles/home'),
            item: (slug) => get(`articles/${slug}`),
            view: (slug) => post(`articles/view/${slug}`),
            massMedia: get('mass-media-articles'),
        },

        vacancies: get('vacancies'),

        record: post('records/add'),
        customRecord: post('records/add-custom'),
        creditAttorney: post('records/add-credit-attorney'),
        test: post('site-tests'),

        areas: get('areas'),
        cityFromIp: get('cities/from-ip'),

        social: get('social-networks'),

        fillCache,
        extractCache,
    }
}

export default createApi(() => cookies.get('atl_token'))
